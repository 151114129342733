import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class athleteFileRepository {

    /**
     * Get athleteFiles
     * @returns {Promise}
     * @param query
     */
    static getAthleteFiles(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athleteFile/mobile/${helperService.serialize(query)}`
        );
    }

    /**
     * Get athleteFile by id
     * @returns {Promise}
     * @param id
     */
    static getAthleteFileById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athleteFile/${id}`
        );
    }

    /**
     * Get athleteFile by athlete id
     * @returns {Promise}
     * @param athleteId
     */
    static getAthleteFileByAthleteId(athleteId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athleteFile/athlete/${athleteId}`
        );
    }

    /**
     * Get athleteFiles count
     * @returns {Promise}
     */
    static getAthleteFilesCount(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athleteFile/count/${helperService.serialize(query)}`
        );
    }

    /**
     * Update athleteFile
     * @returns {Promise}
     */
    static updateAthleteFile(athleteFile) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athleteFile`, {
            method: 'PUT',
            body: JSON.stringify(athleteFile)
        });
    }

    /**
     * Add medical visa to athlete files
     * @returns {Promise}
     */
    static addMedicalVisaAthleteFiles(athleteFileBulkActionRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athleteFile/bulk/addMedicalVisa`, {
            method: 'PATCH',
            body: JSON.stringify(athleteFileBulkActionRequest)
        });
    }
    /**
     * Edit medical visa to athlete files
     * @returns {Promise}
     */
    static editMedicalVisaAthleteFiles(athleteFileBulkActionRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athleteFile/bulk/editMedicalVisa`, {
            method: 'PATCH',
            body: JSON.stringify(athleteFileBulkActionRequest)
        });
    }

}
