import {athleteRepository} from "../repositories/athleteRepository";

class AthleteService {

    getAthletes = (query) => {
        return athleteRepository.getAthletes(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAthletesMobile = (query) => {
        return athleteRepository.getAthletesMobile(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAthleteById = (id) => {
        return athleteRepository.getAthleteById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAthleteByAccessUserId = (id) => {
        return athleteRepository.getAthleteByAccessUserId(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getParentListByAthleteId = (id) => {
        return athleteRepository.getParentListByAthleteId(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAthletesBirthYear = () => {
        return athleteRepository.getAthletesBirthYear()
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getSubscriptionTypeByAthleteId = (id) => {
        return athleteRepository.getSubscriptionTypeByAthleteId(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateAthlete = (athlete) => {
        return athleteRepository.updateAthlete(athlete);
    };

    deleteAthlete = (athlete) => {
        return athleteRepository.deleteAthlete(athlete);
    };

    updateParentsForAthlete = (parents, athleteId) => {
        return athleteRepository.updateParentsForAthlete(parents, athleteId);
    };

    updateAthleteProperties = (athleteRequest) => {
        return athleteRepository.updateAthleteProperties(athleteRequest);
    };

    updateAthletesStatus = (statusBulkActionRequest) => {
        return athleteRepository.updateAthletesStatus(statusBulkActionRequest);
    };

    updateAthletesSubscription = (subscriptionBulkActionRequest) => {
        return athleteRepository.updateAthletesSubscription(subscriptionBulkActionRequest);
    };

    deleteAthletes = (bulkActionRequest) => {
        return athleteRepository.deleteAthletes(bulkActionRequest);
    };

    updateAthletesTeams = (teamsBulkActionRequest) => {
        return athleteRepository.updateAthletesTeams(teamsBulkActionRequest);
    };

    getAthletesCount = (query) => {
        return athleteRepository.getAthletesCount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

}

export default new AthleteService();
