import {athleteFileRepository} from "../repositories/athleteFileRepository";
import AthleteFile from "../models/club/AthleteFile";

class AthleteFileService {

    getAthleteFiles = (query) => {
        return athleteFileRepository.getAthleteFiles(query)
            .then((res) => {
                if (Array.isArray(res)){
                    return res.map(el => new AthleteFile(el));
                }
                return Promise.reject(res);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAthleteFileById = (id) => {
        return athleteFileRepository.getAthleteFileById(id)
            .then((res) => {
                if (res) {
                    return new AthleteFile(res);
                }
                return null;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAthleteFileByAthleteId = (athleteId) => {
        return athleteFileRepository.getAthleteFileByAthleteId(athleteId)
            .then((res) => {
                if (res) {
                    return new AthleteFile(res);
                }
                return null;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getAthleteFilesCount = (query) => {
        return athleteFileRepository.getAthleteFilesCount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    updateAthleteFile = (athleteFile) => {
        return athleteFileRepository.updateAthleteFile(athleteFile);
    };
    addMedicalVisaAthleteFiles = (athleteFileBulkActionRequest) => {
        return athleteFileRepository.addMedicalVisaAthleteFiles(athleteFileBulkActionRequest);
    };
    editMedicalVisaAthleteFiles = (athleteFileBulkActionRequest) => {
        return athleteFileRepository.editMedicalVisaAthleteFiles(athleteFileBulkActionRequest);
    };

}

export default new AthleteFileService();
