import Camp from "../models/club/Camp";
import Competition from "../models/club/Competition";
import * as moment from "moment";
import {EUserStatus} from "../enums/EUserStatus";
import i18n from "i18next";
import {EMonthsTranslated} from "../enums/EMonths";
import {EDaysTranslated} from "../enums/EDaysOfWeek";

export class helperService {
    /**
     * Convert object to query string
     * @param {Object} obj
     * @returns {string}
     */
    static serialize(obj) {
        if (Object.keys(obj).length === 0 && obj.constructor === Object) {
            return '';
        }
        return (
            '?' +
            Object.keys(obj)
                .reduce((a, k) => {
                    a.push(k + '=' + encodeURIComponent(obj[k]));
                    return a;
                }, [])
                .join('&')
        );
    }

    static capitalize (word) {
        if (typeof word !== 'string') {
            return;
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }

    static upperCase (word) {
        if (typeof word !== 'string') {
            return;
        }
        return word.toUpperCase();
    }

    static lowerCase (word) {
        if (typeof word !== 'string') {
            return;
        }
        return word.toLowerCase();
    }

    static formatFirstName (value) {

        if (typeof value !== 'string') {
            return;
        }

        const words = value.split(" ");
        for (let i = 0; i < words.length; i++) {
            words[i] = helperService.capitalize(words[i]);
        }
        return words.join(" ");
    }

    static formatQuery = query => {
        Object.keys(query).forEach((key) => (query[key] === '' || query[key] === 'All') && delete query[key]);
        return query;
    };

    static formatQueryNoPagination = query => {
        Object.keys(query).forEach((key) => (query[key] === '' || query[key] === 'All' || key === 'pagination') && delete query[key]);
        return query;
    };

    static formatDate = date => {
        if(new Date(date).toString() === 'Invalid Date') {
            return date;
        }
        return new Date(date).toISOString().slice(0, 10);
    };

    static addDays = (date, days) => {
        let result = new Date(date);
        result.setDate(result.getDate()+days);
        return result;
    }

    static addMonths = (date, months) => {
        let result = new Date(date);
        result.setMonth(result.getMonth()+months);
        return result;
    }

    static addYears = (date, years) => {
        let result = new Date(date);
        result.setFullYear(result.getFullYear()+years);
        return result;
    }

    static dateUniversalFormat = date => {
        if(date.length === 10)
            return date;
        let year = new Date(date).getUTCFullYear();
        let month = (1 + new Date(date).getUTCMonth()).toString().padStart(2, '0');
        let day = new Date(date).getUTCDate().toString().padStart(2, '0');
        // console.log("day-month-year: ", day, month, year);
        return day + '-' + month + '-' + year;
    };

    static getEndOfDay = (date) => {
        if (date) {
            date.setHours(23, 59, 59, 999);
            return date;
        } else {
            const currentDate = new Date();
            currentDate.setHours(23, 59, 59, 999);
            return currentDate;
        }
    };

    static getStartOfDay = (date) => {
        if (date) {
            date.setHours(0, 0, 0, 0);
            return date;
        } else {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            return currentDate;
        }
    };

    static getLastDayOfMonth = (date) => {
        return new Date(date.getFullYear(), date.getMonth()+1, 0);
    };

    static getMonth = date => {
        return new Date(date).getMonth()+1;
    };

    static formatDateTime = dateStr =>  {
        let date = new Date(dateStr);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0'+minutes : minutes;
        const strTime = hours + ':' + minutes + ' ' + ampm;
        return (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) + "-" +
            (date.getMonth()+1 > 9 ? (date.getMonth()+1) : "0" + (date.getMonth()+1)) + "-" +
            date.getFullYear() +"  " +
            strTime;
    };

    static datesAreOnSameDay = (first, second) => {
        return first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate();
    };

    static monthDifference(startDate, endDate) {
        if (startDate instanceof Date && endDate instanceof Date) {
            let months = endDate.getMonth() - startDate.getMonth() + (12 * (endDate.getFullYear() - startDate.getFullYear()));
            if (endDate.getDate() < startDate.getDate()) {
                months--;
            }
            return months;
        } else {
            return null;
        }
    }

    static getStartTimes = (date = null) => {
        const times = [];
        let time = '';
        let hourIndex = 0;
        let minuteIndex = 0;
        if (date != null && this.datesAreOnSameDay(date, new Date())) {
            const roundedTime = this.getRoundedStartTime(moment());
            hourIndex = parseInt(roundedTime.split(':')[0]);
            minuteIndex = parseInt(roundedTime.split(':')[1]);
        }
        for (let j = hourIndex; j < 24; j++){
            let hour = j < 10 ? ('0' + j) : j;
            time = hour;
            for (let k = minuteIndex; k < 60; k=k+15) {
                time += ':' + (k === 0 ? '00' : k);
                times.push(time);
                time = hour;
            }
            minuteIndex = 0;
        }
        return times;
    };

    static getStopTimes = (startDates, startDate) => {
        const stopDates = [...startDates];
        const index = stopDates.indexOf(startDate);
        for (let i = 0; i < index; i++) {
            stopDates.push(stopDates.shift());
        }
        return stopDates;
    };

    static getRoundedStartTime = (currentDate) => {
        const minutes = currentDate.get('minute');
        const roundedMinutes = (15 * Math.ceil(minutes / 15)); //(Math.round(minutes/15) * 15) % 60;
        currentDate.set("minute", roundedMinutes);
        return currentDate.format('HH:mm');
    };

    static getStopTime = (currentDate) => {
        return currentDate.add(90, 'minutes').format('HH:mm');
    };

    static getDateTranslated = (date, language) => {
        const userSetLng = i18n.getFixedT(language.toLowerCase());

        const month = userSetLng(EMonthsTranslated[new Date(date).getUTCMonth() + 1]);
        const year = new Date(date).getUTCFullYear();
        const day = new Date(date).getUTCDate().toString().padStart(2, '0');
        const dayOfWeek = new Date(date).getDay();

        return userSetLng(EDaysTranslated[dayOfWeek]) + ', ' + day + ' ' + month + ' ' + year;
    }
    static getMonthList(locales, format = "short") {
        const year = new Date().getFullYear();
        const monthList = [...Array(12).keys()];
        const formatter = new Intl.DateTimeFormat(locales, {
            month: format
        });

        const getMonthName = (monthIndex) =>
            formatter.format(new Date(year, monthIndex));

        return monthList.map(getMonthName);
    }


    static getId(str) {
        if (str) {
            return str.split('/').pop();
        }
    }

    static focus(id) {
        if (document.getElementById(id)) {
            document.getElementById(id).focus();
        }
    }

    static blur(id) {
        if (document.getElementById(id)) {
            document.getElementById(id).blur();
        }
    }

    static goToEvent(eventExpenses, history){
        let event = null;
        let path = '';
        if(eventExpenses && eventExpenses.camp && eventExpenses.eventType === 'CAMP') {
            event = new Camp(eventExpenses.camp);
            path = '/calendar/camp';
        }
        else if(eventExpenses && eventExpenses.competition && eventExpenses.eventType === 'GAME') {
            event = new Competition(eventExpenses.competition);
            path = '/calendar/game';
        }

        if(event !== null) {
            const eventDate = event.start;
            const calendarDate = new Date();
            calendarDate.setYear(eventDate.getYear());
            calendarDate.setMonth(eventDate.getMonth());
            calendarDate.setDate(eventDate.getDate());
            history.push({
                pathname: path,
                state: {
                    event: event,
                    calendarDate: calendarDate,
                    displayEvent: true,
                    displayArrow: true
                }
            });
        }

    }

    static formatDateMongo(date) {
        return new Date(moment(date).format("YYYY-MM-DD"))
    }

    static getTimezoneOffset = (value) => value.getTimezoneOffset() * 60000;

    static makeLocalAppearUTC = value => {
        const dateTime = new Date(value);
        return new Date(dateTime.getTime() + this.getTimezoneOffset(dateTime));
    };

    static localToUTC = dateTime => {
        return new Date(dateTime.getTime() - this.getTimezoneOffset(dateTime));
    };

    static sortUsersByLastname = (users) => {
        if(users && users.length>0){
            users.sort((a, b) => {
                if( a.lastName && b.lastName) {
                    let la = a.lastName.toUpperCase(),
                        lb = b.lastName.toUpperCase();
                    if (la < lb) {
                        return -1;
                    }
                    if (la > lb) {
                        return 1;
                    }
                    return 0;
                }
            });
        }
        return users;
    };

    static getCoachesFromTeams = (teams) => {
        const notDuplicatedCoaches = [];
        teams.forEach(team => {
            team.coaches.forEach(coach => {
                const coachFound = notDuplicatedCoaches.find(c => c.id === coach.id);
                if (!coachFound && coach.accessUser.status === EUserStatus.ACTIVE) {
                    notDuplicatedCoaches.push(coach);
                }
            });
        });
        return this.sortUsersByLastname(notDuplicatedCoaches);
    };

    static getCoachesFromTeamsMobile = (teams) => {
        const notDuplicatedCoaches = [];
        teams.forEach(team => {
            team.coaches.forEach(coach => {
                const coachFound = notDuplicatedCoaches.find(c => c.id === coach.id);
                if (!coachFound && coach.status === EUserStatus.ACTIVE) {
                    notDuplicatedCoaches.push(coach);
                }
            });
        });
        return this.sortUsersByLastname(notDuplicatedCoaches);
    };

    static getAthletesFromTeams = (teams) => {
        let athletes = [];
        teams.forEach(team => {
            athletes.push(...this.getAthletesFromTeam(team));
        })

        return this.sortUsersByLastname(athletes);
    };

    static getAthletesFromTeamsMobile = (teams) => {
        let athletes = [];
        teams.forEach(team => {
            athletes.push(...this.getAthletesFromTeamMobile(team));
        });

        return this.sortUsersByLastname(athletes);
    };

    static getAthletesFromTeam = (team) => {
        const notDuplicatedAthletes = [];
        team.athletes.forEach(athlete => {
            const athleteFound = notDuplicatedAthletes.find(a => a.id === athlete.id);
            if (!athleteFound && athlete.accessUser.status === EUserStatus.ACTIVE) {
                notDuplicatedAthletes.push(athlete);
            }
        });
        return this.sortUsersByLastname(notDuplicatedAthletes);
    };

    static getAthletesFromTeamMobile = (team) => {
        const notDuplicatedAthletes = [];
        team.athletes.forEach(athlete => {
            const athleteFound = notDuplicatedAthletes.find(a => a.id === athlete.id);
            if (!athleteFound && athlete.status === EUserStatus.ACTIVE) {
                notDuplicatedAthletes.push(athlete);
            }
        });
        return this.sortUsersByLastname(notDuplicatedAthletes);
    };

    static getActiveUsers = (users) => {
        return users ? this.sortUsersByLastname(users.filter(user => user.accessUser.status === EUserStatus.ACTIVE)) : [];
    };

    static getActiveUsersMobile = (users) => {
        return users ? this.sortUsersByLastname(users.filter(user => user.status === EUserStatus.ACTIVE)) : [];
    };

    static computePercentage = (lastValue, newValue) => {
        let percentage = 0;
        if (lastValue != null) {
            percentage = Math.round((newValue - lastValue) / lastValue * 100)
        }
        return percentage;
    }
}
