import React, {Component} from "react";
import {
    Card,
    Grid,
    Button,
    withStyles,
    CircularProgress, FormControlLabel, Checkbox, Icon, IconButton
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import { auth, eraseLoginError } from "../../redux/actions/LoginActions";
import CustomizedSnackbar from "../../shared/components/CustomizedSnackbar";
import {eraseRegisterMessage} from "../../redux/actions/RegisterActions";
import {eraseResetPasswordMessage} from "../../redux/actions/ResetPasswordActions";
import {withTranslation} from "react-i18next";
import * as ReactDOM from "react-dom";
import ReactFlagsSelect from 'react-flags-select';
import {Visibility, VisibilityOff} from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";

const styles = theme => ({
    wrapper: {
        position: "relative"
    },

    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },

    logo: {
        height: "100px",
        transform: "scale(2.2)",
        transformOrigin: "center",
        paddingTop: "15px"
    },
    column: {
        float: "left",
        width: "30%",
        padding: "5px"
    },
    row: {
        content: "",
        clear: "both",
        display: "table"
    }
});

class SignIn extends Component {
    state = {
        email: "",
        password: "",
        keepLogin: false,
        showPassword: false,
        agreement: "",
        selectedLanguage: ''
    };

    constructor(props) {
        super(props);
        this.setInputFocus = this.setInputFocus.bind(this);
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            selectedLanguage: this.props.i18n.language === 'en' ? 'GB' : this.props.i18n.language.toUpperCase()
        });
    }

    setInputFocus() {
        let emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( !emailFormat.test(this.state.email) ) {
            ReactDOM.findDOMNode(this.emailRef).childNodes[1].childNodes[0].focus();
        } else if ( this.state.password === '') {
            ReactDOM.findDOMNode(this.passwordRef).childNodes[1].childNodes[0].focus();
        }
    }

    handleChange = event => {
        event.persist();
        this.setState({
            [event.target.name]: event.target.value
        });
    };
    handleFormSubmit = event => {
        this.props.auth({ ...this.state });
    };

    handleLanguageClick = (lng) => {
        const {i18n} = this.props;
        i18n.changeLanguage(lng).then();
    };

    handleKeepLoginChange = event => {
        event.persist();
        const keepLogin = (!this.state.keepLogin);
        this.setState({
            ...this.state,
            keepLogin
        });
    };

    renderLanguage = () => {
        return (
            <ReactFlagsSelect
                countries={["RO", "GB"]}
                showOptionLabel={false}
                showSelectedLabel={false}
                defaultCountry="RO"
                selected={this.state.selectedLanguage}
                selectedSize={30}
                optionsSize={30}
                onSelect={this.onSelectFlag}
                selectButtonClassName="language-signup"
            />
        );
    };

    onSelectFlag = (countryCode) => {
        switch(countryCode) {
            case 'RO':
                this.handleLanguageClick('ro');
                this.setState({
                    ...this.state,
                    selectedLanguage: 'RO'
                });
                break;
            case 'GB':
                this.handleLanguageClick('en');
                this.setState({
                    ...this.state,
                    selectedLanguage: 'GB'
                });
                break;
            default:
                this.handleLanguageClick('ro');
                this.setState({
                    ...this.state,
                    selectedLanguage: 'RO'
                });
                break;
        }
    };

    togglePasswordVisibility = () => {
        const showPassword = (!this.state.showPassword);
        this.setState({
            ...this.state,
            showPassword
        });
    }

    render() {
        let { email, password, keepLogin, showPassword } = this.state;
        let { classes, t, login, register, resetPassword } = this.props;

        return (
            <div>
                <div style={{float: 'right !important', position: 'absolute', right: '7%', top:'13.5%'}}>
                    {this.renderLanguage()}
                </div>
                <div className="signup flex flex-center w-100 h-100vh">
                    <div className="p-8">
                    <Card className="signup-card position-relative y-center">
                        <Grid container>
                            <Grid item lg={5} md={5} sm={5} xs={12}>
                                <div className="p-32 bg-light-gray h-100 text-center">
                                    <div className="uppercase" style={{position:'relative', top:'4px', whiteSpace: 'pre-line'}}>
                                        <h3>{t('signIn.title')}</h3>
                                    </div>
                                    <img src="/assets/logo/Logo-Expert-Sport-Club-Outlined-02.svg"
                                         alt="" className={classes.logo}/>
                                        <p>{t('signIn.clubsDetails')}</p>
                                        <p>{t('signIn.activeUsersDetails')}</p>
                                        <p>{t('signIn.subscriptionDetails')}</p>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                            this.props.history.push("/session/club/add")
                                        }
                                    >
                                        {t('signIn.buttons.clubRegister')}
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item lg={7} md={7} sm={7} xs={12}>
                                <div className="p-36 h-100">
                                    <div className="mb-16 w-100"><h3>{t('signIn.details')}</h3></div>
                                    <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                                        <TextValidator
                                            className="mb-24 w-100"
                                            variant="outlined"
                                            label={t('signIn.email')}
                                            onChange={this.handleChange}
                                            type="text"
                                            name="email"
                                            value={email}
                                            validators={["required", "isEmail"]}
                                            errorMessages={[
                                                t('validation.error.required'),
                                                t('validation.error.email.invalid')
                                            ]}
                                            ref={c => (this.emailRef = c)}
                                            autoFocus
                                        />
                                        <div className="flex flex-middle mb-8">
                                        <TextValidator
                                            className="mb-16 w-100"
                                            label={t('signIn.password')}
                                            variant="outlined"
                                            onChange={this.handleChange}
                                            name="password"
                                            type={showPassword === true ? "text" :"password"}
                                            value={password}
                                            validators={["required"]}
                                            errorMessages={[t('validation.error.required')]}
                                            ref={c => (this.passwordRef = c)}
                                        />
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                color="primary"
                                                onClick={this.togglePasswordVisibility}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                        </div>
                                        <div className="flex flex-middle mb-8">
                                            <div className={classes.wrapper}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={login.loading}
                                                    type="submit"
                                                    onClick={this.setInputFocus}
                                                >
                                                    {t('actions.signIn')}
                                                    {login.loading && (
                                                        <CircularProgress
                                                            size={24}
                                                            className={classes.buttonProgress}
                                                        />
                                                    )}
                                                </Button>
                                            </div>
                                            <div style={{float: 'right !important', position: 'absolute', right: '7%'}}>
                                                <FormControlLabel
                                                    name="keepLogin"
                                                    onChange={this.handleKeepLoginChange}
                                                    control={<Checkbox disabled = {false}/>}
                                                    checked={keepLogin ? (keepLogin || false) : false}
                                                    label={t("signIn.keepLogin")}
                                                />
                                            </div>

                                        </div>
                                        <Button
                                            className="text-primary"
                                            onClick={() =>
                                                this.props.history.push("/session/forgot-password")
                                            }
                                        >
                                            {t('signIn.buttons.forgotPassword')}
                                        </Button>
                                    </ValidatorForm>
                                    <div className={classes.row}>
                                        <div className={classes.column}>
                                            <a href="https://play.google.com/store/apps/details?id=com.nodesoft.esc" target="_blank">
                                                <img src="/assets/logo/google-play-store.svg"
                                                 alt=""  title={t('signIn.mobileApp')} />
                                            </a>
                                        </div>
                                        <div className={classes.column}>
                                            <a href="https://apps.apple.com/ro/app/expert-sport-club-esc/id6444104408" target="_blank">
                                                <img src="/assets/logo/apple-app-store.svg"
                                                     alt="" title={t('signIn.mobileApp')} />
                                            </a>
                                        </div>
                                        <div className={classes.column}>
                                            <a href="https://appgallery.huawei.com/app/C109229187" target="_blank">
                                                <img src="/assets/logo/huawei-app-gallery.png"
                                                     alt="" title={t('signIn.mobileApp')} style={{marginTop: "37px", height: "36px"}}/>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </Grid>
                        </Grid>
                        <div className="footer flex flex-middle">
                            <div className="flex flex-middle container px-sm-30 w-100">
                                <h5 className="text-black">© 2024 NODESOFT SRL | All rights reserved | <a href="https://expertsport.club/" target="_blank"> <u>expertsport.club</u> </a></h5>
                            </div>
                        </div>

                    </Card>
                    <CustomizedSnackbar message={login.error} severity="error" onClose={this.props.eraseLoginError}/>
                    <CustomizedSnackbar message={register.success} severity="success" onClose={this.props.eraseRegisterMessage}/>
                    <CustomizedSnackbar message={register.error} severity="error" onClose={this.props.eraseRegisterMessage}/>
                    <CustomizedSnackbar message={resetPassword.success} severity="success" onClose={this.props.eraseResetPasswordMessage}/>
                </div>
            </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: PropTypes.func.isRequired,
    login: state.login,
    register: state.register,
    resetPassword: state.resetPassword
});

export default
    connect(
        mapStateToProps,
        { auth, eraseLoginError, eraseRegisterMessage, eraseResetPasswordMessage }
        )(withStyles(styles, { withTheme: true })((withTranslation()(SignIn)))
);
