import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class athleteRepository {

    /**
     * Get athletes
     * @returns {Promise}
     * @param query
     */
    static getAthletes(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athlete/${helperService.serialize(query)}`
        );
    }

    /**
     * Get athletes for mobile
     * @returns {Promise}
     * @param query
     */
    static getAthletesMobile(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athlete/all/${helperService.serialize(query)}`
        );
    }
    /**
     * Get athlete by id
     * @returns {Promise}
     * @param id
     */
    static getAthleteById(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athlete/${id}`
        );
    }

    /**
     * Get athlete by accessUser id
     * @returns {Promise}
     * @param id
     */
    static getAthleteByAccessUserId(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athlete/access-user/${id}`
        );
    }

    /**
     * Get athletes birth year
     * @returns {Promise}
     */
    static getAthletesBirthYear() {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athlete/birthyear`
        );
    }

    /**
     * Get parents for specific athlete
     * @returns {Promise}
     * @param id
     */
    static getParentListByAthleteId(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athlete/parents/${id}`
        );
    }

    /**
     * Get subscription type for specific athlete
     * @returns {Promise}
     * @param id
     */
    static getSubscriptionTypeByAthleteId(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athlete/subscriptionType/${id}`
        );
    }

    /**
     * Update parents for specific athlete
     * @returns {Promise}
     */
    static updateParentsForAthlete(parents, athleteId) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athlete/parents/${athleteId}`, {
            method: 'PUT',
            body: JSON.stringify(parents)
        });
    }

    /**
     * Update athlete
     * @returns {Promise}
     */
    static updateAthlete(athlete) {
        return FetchAdapter.request(`${APP_CONFIG.URL.athlete}/`, {
            method: 'PUT',
            body: JSON.stringify(athlete)
        });
    }

    /**
     * Delete athlete
     * @returns {Promise}
     */
    static deleteAthlete(athlete) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athlete/${athlete.id}`, {
            method: 'DELETE'
        });
    }

    /**
     * Update athlete properties
     * @returns {Promise}
     */
    static updateAthleteProperties(athleteRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athlete/`, {
            method: 'PATCH',
            body: JSON.stringify(athleteRequest)
        });
    }

    /**
     * Update athletes status
     * @returns {Promise}
     */
    static updateAthletesStatus(statusBulkActionRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athlete/bulkStatus`, {
            method: 'PATCH',
            body: JSON.stringify(statusBulkActionRequest)
        });
    }

    /**
     * Update athletes subscription
     * @returns {Promise}
     */
    static updateAthletesSubscription(subscriptionBulkActionRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athlete/bulkSubscription`, {
            method: 'PATCH',
            body: JSON.stringify(subscriptionBulkActionRequest)
        });
    }

    /**
     * Update athletes teams
     * @returns {Promise}
     */
    static updateAthletesTeams(teamsBulkActionRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athlete/bulkTeams`, {
            method: 'PATCH',
            body: JSON.stringify(teamsBulkActionRequest)
        });
    }

    /**
     * Delete bulk athletes
     * @returns {Promise}
     */
    static deleteAthletes(bulkActionRequest) {
        return FetchAdapter.request(`${APP_CONFIG.URL.club}/athlete/bulk`, {
            method: 'DELETE',
            body: JSON.stringify(bulkActionRequest)
        });
    }

    /**
     * Get athletes count
     * @returns {Promise}
     */
    static getAthletesCount(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.club}/athlete/count/${helperService.serialize(query)}`
        );
    }

}
