import {subscriptionRepository} from "../repositories/subscriptionRepository";

class SubscriptionService {

    getSubscription = (query) => {
        return subscriptionRepository.getSubscription(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    getSubscriptionById = (id) => {
        return subscriptionRepository.getSubscriptionById(id)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    deleteSubscription = (subscription) => {
        return subscriptionRepository.deleteSubscription(subscription);
    };
    deleteSubscriptions = (bulkActionRequest) => {
        return subscriptionRepository.deleteSubscriptions(bulkActionRequest);
    };

    getSubscriptionCount = (query) => {
        return subscriptionRepository.getSubscriptionCount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    sendPaymentReminder = (subscriptionId) => {
        return subscriptionRepository.sendPaymentReminder(subscriptionId);
    };
    sendBulkPaymentReminder = (bulkActionRequest) => {
        return subscriptionRepository.sendBulkPaymentReminder(bulkActionRequest);
    };

    cancelSubscription = (subscriptionId) => {
        return subscriptionRepository.cancelSubscription(subscriptionId);
    };
    getSubscriptionsTotalAmount = (query) => {
        return subscriptionRepository.getSubscriptionsTotalAmount(query)
            .then((res) => {
                return res;
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

}

export default new SubscriptionService();
