import {FetchAdapter} from "../adapters/fetchAdapter";
import {APP_CONFIG} from "../../appConfig";
import {helperService} from "../services/helperService";

export class pdfReportRepository {

    /**
     * Get user language
     * @returns {Promise}
     */
    static getUserLanguage() {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/user/language`
        );
    }
    /**
     * Download Athletes List
     * @returns {Promise}
     * @param query
     */
    static downloadAthletesList(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/athletes/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Monthly Athletes Attendances List
     * @returns {Promise}
     * @param teamId
     * @param month
     * @param year
     */
    static downloadMonthlyAttendancesPdfReport(teamId, month, year) {
        // return FetchAdapter.request(
        //     `${APP_CONFIG.URL.club}/training/monthly-athletes-attendance-situation/${teamId}?month=${month}&year=${year}`);

        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/monthly-athletes-attendance-situation/${teamId}?month=${month}&year=${year}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Staff List
     * @returns {Promise}
     * @param query
     */
    static downloadStaffList(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/staff/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Tests Results Athletes List
     * @returns {Promise}
     * @param query
     */
    static downloadTestsResultsAthletesList(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/testResults/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Activity Results Athletes List
     * @returns {Promise}
     * @param query
     */
    static downloadActivityResultsAthletesList(query = {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/activityResults/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Game Attendance Athletes and Staff List
     * @returns {Promise}
     * @param id
     */
    static downloadGameAttendanceList(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/competition/${id}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Game Equipments Athlete List
     * @returns {Promise}
     * @param id
     */
    static downloadGameEquipmentsAthleteList(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/athleteFile/${id}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download General Data Athlete
     * @returns {Promise}
     * @param id
     */
    static downloadGeneralDataAthlete(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/athlete/${id}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Biometric Data Athlete
     * @returns {Promise}
     * @param id
     */
    static downloadBiometricDataAthlete(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/athleteFile/biometricData/${id}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Invoice Data
     * @returns {Promise}
     * @param id
     */
    static downloadInvoiceData(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/invoice/report/${id}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Team Athletes and Staff
     * @returns {Promise}
     * @param id
     */
    static downloadTeamInfo(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/team/${id}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

        /**
         * Download Certificate Athlete
         * @returns {Promise}
         * @param id
         */
        static downloadCertificateAthlete(id) {
            return FetchAdapter.request(
                `${APP_CONFIG.URL.root}/report/club/athlete/access-user/${id}`,
                {
                    headers: {
                        'Accept': 'application/pdf'
                    }
                }, true);
        }

    /**
     * Download Athlete Register File
     * @returns {Promise}
     * @param id
     */
    static downloadAthleteRegisterFile(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/athlete/register-file/${id}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Club Invoice
     * @returns {Promise}
     * @param id
     */
    static downloadClubInvoice(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/extra-invoice/${id}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }
    /**
     * Download All Club Extra Invoices
     * @returns {Promise}
     * @param query
     */
    static downloadClubExtraInvoices(query= {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/extra-invoice/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download All Club Extra Notices Invoices
     * @returns {Promise}
     * @param query
     */
    static downloadClubExtraNoticeInvoices(query= {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/extraNotice-invoice/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }
    /**
     * Download Athlete Payment Invoice
     * @returns {Promise}
     * @param id
     */
    static downloadAthletePaymentInvoice(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/athlete-invoice/${id}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }
    /**
     * Download All Athlete Payment Invoices
     * @returns {Promise}
     * @param query
     */
    static downloadAthletePaymentInvoices(query= {}) {
        // console.log("query: ", query);
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/athlete-invoice/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download All Athlete Subscription Invoices
     * @returns {Promise}
     * @param query
     */
    static downloadAthleteSubscriptionInvoices(query= {}) {
        // console.log("query: ", query);
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/athleteSubscription-invoice/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Event Payment Invoice
     * @returns {Promise}
     * @param id
     */
    static downloadEventPaymentInvoice(id) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/event-invoice/${id}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }
    /**
     * Download All Event Payment Invoices
     * @returns {Promise}
     * @param query
     */
    static downloadEventPaymentInvoices(query= {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/event-invoice/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download All Event Expenses Invoices
     * @returns {Promise}
     * @param query
     */
    static downloadEventExpensesInvoices(query= {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/eventExpenses-invoice/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Monthly Coach Activity
     * @returns {Promise}
     * @param teamId
     * @param coachId
     * @param month
     * @param year
     */
    static downloadMonthlyCoachActivityPdfReport(teamId, coachId, month, year) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/monthly-coach-activity/${teamId}/${coachId}?month=${month}&year=${year}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }
    /**
     * Download Club AthleteFiles Card Situation
     * @returns {Promise}
     */
    static downloadCardSituationAthlete() {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/athleteFile/cardSituation`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }

    /**
     * Download Athlete TestResults
     * @returns {Promise}
     */
    static downloadTestResultsAthlete(athleteId, testPlanId) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/testResults/athlete?athleteId=${athleteId}&testPlanId=${testPlanId}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }
    /**
     * Download All Athlete Files
     * @returns {Promise}
     */
    static downloadAthleteFiles(query= {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/athleteFiles/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }
    /**
     * Download Athlete Payments
     * @returns {Promise}
     */
    static downloadAthletePayments(query= {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/athletePayment/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }
    /**
     * Download Event Payments
     * @returns {Promise}
     */
    static downloadEventPayments(query= {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/eventPayment/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }
    /**
     * Download Extra Payments
     * @returns {Promise}
     */
    static downloadExtraPayments(query= {}) {
        return FetchAdapter.request(
            `${APP_CONFIG.URL.root}/report/club/extraPayment/${helperService.serialize(query)}`,
            {
                headers: {
                    'Accept': 'application/pdf'
                }
            }, true);
    }
}
